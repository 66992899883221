import { LitElement, PropertyValues, unsafeCSS } from 'lit';
import { html } from 'lit/html.js';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { repeat } from 'lit/directives/repeat.js';
import { getHref } from '../core/resolvers';
import { isFocusWithin, isMobile as getIsMobile, safeJSONParse } from '../core/utils';
import { when } from '../libs/custom-lit-directives/when';
import { animate } from '../shared/animate';
import { toggleBackgroundOverlay } from '../shared/utils/backgroundOverlay';
import './apps-option/gntc-apps-option';
import { GntcGlobalHeaderBaseMixin } from './gntc-global-header-base-mixin';
import { GntcGlobalHeaderBaseOptionsMixin } from './gntc-global-header-base-options-mixin';
import {
  generateHeaderMenuToggleTriggers,
  HeaderMenus,
  onCloseHeaderMenuEvent,
} from './gntc-global-header.events';
import {
  ContentGroup,
  Language,
  LanguageConfig,
  MenuGroup,
  MenuSection,
  NotificationConfig,
  SessionConfig,
} from './gntc-global-header.interface';
import useStyles from './gntc-global-header.styles';
import './language-list/gntc-language-list';
import './login-section/gntc-login-section';
import { GlobalMainNavigation } from './main-navigation/gntc-main-navigation';
import './notifications-option/gntc-notifications-option';
import './search-navigation/gntc-search-navigation';

const [emitGlobalHeaderNavigationToggle, onOtherHeaderOptionToggled] =
  generateHeaderMenuToggleTriggers(HeaderMenus.GlobalNavigation);
export class GlobalHeaderBase extends LitElement {
  static styles = unsafeCSS(useStyles.toString());

  @query('.header-wrapper')
  rootElement?: HTMLElement;

  @query('.ribbon')
  ribbonElement?: HTMLElement;

  @query('[name="news-ribbon"]')
  slotRibbon?: HTMLSlotElement;

  @query('[name="gnct-notifications-content-slot"]')
  slotNotifications?: HTMLSlotElement;

  @query('.nav-mobile')
  navMobileElement?: HTMLElement;

  @query('.header')
  headerElement?: HTMLElement;

  @query('.header-progress')
  headerProgressElement?: HTMLElement;

  @query('.header-progress-bar')
  headerProgressBarElement?: HTMLElement;

  @query(`.nav`)
  navElement?: HTMLElement;

  @query(`.nav-list`)
  navListElement?: HTMLElement;

  @query(`#nav-list-mobile`)
  navListMobileElement?: HTMLElement;

  @query('gntc-search-navigation')
  searchNavigationElementRef?: HTMLElement;

  @query('#mobile-primary-navigation')
  mobilePrimaryNavigationRef?: HTMLElement;

  @property({ type: Boolean, reflect: true })
  protected isExternal = false;

  @property({ type: String, reflect: false })
  protected cmsbaseurl = '';

  @property({ type: String, reflect: true })
  protected spaBaseUrl = '';

  @property({ type: Boolean, reflect: true })
  protected campaign = false;

  @property({ type: String, reflect: true })
  protected searchToken = '';

  @property({ type: String, reflect: true })
  protected searchEngine = '';

  @property({ type: String, reflect: true })
  protected searchAuthEngine = '';

  @property({ type: String, reflect: true })
  protected searchBaseUrl = `${this.spaBaseUrl}/search`;

  @property({ type: Boolean, reflect: true })
  protected transparent = false;

  // It's only used in "uberflip"
  @property({ type: Boolean, reflect: true })
  protected sticky = false;

  @property({ type: Boolean, reflect: true })
  protected locked = false;

  @property({ type: Boolean, reflect: true })
  protected hasProgressBar = false;

  @property({ type: Boolean, reflect: true })
  protected loading = false;

  @property({ type: Array, reflect: false })
  protected menuList?: MenuSection[];

  @property({ reflect: false })
  protected config?: Record<string, string>;

  @property({ reflect: false })
  public labels?: Record<string, string>;

  @property({ type: String, reflect: true })
  public linkListTitleStyle = 'standard';

  @property({ type: Array, reflect: true })
  protected languageList?: Language[];

  @property({ type: Array, reflect: true })
  protected loginSection?: MenuGroup[];

  @property({ type: Array, reflect: true })
  protected accountSection?: MenuGroup;

  @property({ type: Array, reflect: true })
  protected appsSection?: ContentGroup;

  @property({ type: String, reflect: false })
  protected headerHeight = 'auto';

  @property({ type: Boolean, reflect: false })
  protected active = false;

  @property({ type: String, reflect: true })
  protected contentTypeLabels? = '';

  @property({ type: String, reflect: true })
  protected showCustomAccountSlotData? = null;

  @property({ reflect: true })
  protected sessionConfig?: Partial<SessionConfig> = {};

  @property({ reflect: true })
  protected languageConfig?: Partial<LanguageConfig> = {};

  @property({ reflect: true })
  protected notificationConfig?: Partial<NotificationConfig> = {};

  @property({ reflect: true })
  protected hideMobileNavigationButton?: boolean = false;

  @property({ reflect: true })
  protected hideNavigation?: boolean = false;

  @query(`#global-header`)
  globalHeaderRef?: HTMLElement;

  @state()
  protected preventTransparent = false;

  @state()
  protected secNavExpanded = false;

  @state()
  public isMobile = getIsMobile();

  @state()
  protected menuItemExpandedNames: any = {};

  @state()
  protected searchContentIsExpanded = false;

  @property({ type: String, reflect: false })
  datalayerVariant?: string = '';

  @state()
  protected secondaryNavigation = new GlobalMainNavigation(this);

  // for getting access to trivial components
  @state()
  protected headerBaseComponentsMixin = new GntcGlobalHeaderBaseMixin(this);

  @state()
  protected headerBaseOptionMixin = new GntcGlobalHeaderBaseOptionsMixin(this);

  @state()
  protected skipNextBlur = false;

  @state()
  protected menuIsOpen = false;

  @state()
  protected isRibbonClosed = false;

  @state()
  protected isRibbonCollapsed = false;

  @state()
  protected headerTopDistance = 0;

  @state()
  protected showMobileGenetecNavigation = true;

  @state()
  protected selectedLanguageCode = '';

  get _showCustomAccountSlotData() {
    return this.showCustomAccountSlotData ? JSON.parse(this.showCustomAccountSlotData) : false;
  }

  // Handlers

  handleLanguageOptionToggle = ({ detail: { isExpanded } }: CustomEvent) => {
    if (!this.isMobile || !this.navListMobileElement) return;
    this.navListMobileElement.style.display = isExpanded ? 'none' : 'block';
  };

  protected primaryNavHandlers = {
    onMouseDown: this.handlePrimaryNavMouseDown.bind(this),
    onKeyDown: this.handlePrimaryNavKeyDown.bind(this),
    onBlur: this.handlePrimaryNavBlur.bind(this),
    onFocus: this.handlePrimaryNavFocus.bind(this),
    onDocumentClick: this.handlePrimaryNavDocumentClick.bind(this),
  };

  protected ribbonHandlers = {
    onExpand: this.handleRibbonExpand.bind(this),
    onCollapse: this.handleRibbonCollapse.bind(this),
    onClose: this.handleRibbonClose.bind(this),
    onReady: this.handleRibbonReady.bind(this),
  };

  protected globalHandlers = {
    onScroll: this.handleScroll.bind(this),
    onProgressScroll: this.handleProgressScroll.bind(this),
    onResize: this.handleResize.bind(this),
  };

  protected onSlotRibbonChange = this.handleSlotRibbonChange.bind(this);

  protected onDocumentResize = this.handleDocumentResize.bind(this) as EventListener;
  protected onOrientationChange = this.handleOrientationChange.bind(this) as EventListener;

  protected getGlobalHeaderTopDistance() {
    this.headerTopDistance = this.globalHeaderRef?.getBoundingClientRect().top || 0;
  }

  protected handleSlotRibbonChange() {
    const slottedRibbon = this.getSlottedRibbon();
    if (slottedRibbon) {
      slottedRibbon.addEventListener('ribbonexpand', this.ribbonHandlers.onExpand);
      slottedRibbon.addEventListener('ribboncollapse', this.ribbonHandlers.onCollapse);
      slottedRibbon.addEventListener('ribbonclose', this.ribbonHandlers.onClose);
      slottedRibbon.addEventListener('ribbonready', this.ribbonHandlers.onReady);
    }
    this.getGlobalHeaderTopDistance();
  }

  private async handleBreakpointChanged() {
    if (this.isMobile === getIsMobile()) return;

    this.isMobile = getIsMobile();
    await this.updateComplete;
    this.addPrimaryNavListeners();
    this.getGlobalHeaderTopDistance();
  }

  protected handleDocumentResize() {
    this.handleBreakpointChanged();
  }

  protected handleOrientationChange() {
    this.handleBreakpointChanged();
  }

  protected handleShadowRootClick(e: MouseEvent) {
    const target = e.target as HTMLElement;
    const isButton = target?.closest('button');

    if (isButton) return;

    this.toggleSecNavigation(null, 'collapse');
    this.toggleMobileNavigationVisibility(false);

    this.style.pointerEvents = 'none';
    setTimeout(() => (this.style.pointerEvents = ''));
  }

  protected handleRibbonExpand() {
    const { height = 0 } = this.getSlottedRibbon()?.getBoundingClientRect() || {};

    if (this.ribbonElement) {
      this.ribbonElement.style.height = `${height}px`;
      this.isRibbonCollapsed = false;
    }
  }

  protected handleRibbonCollapse() {
    if (this.ribbonElement) {
      this.ribbonElement.style.height = '0px';
      this.isRibbonCollapsed = true;
    }
  }

  protected handleRibbonClose() {
    this.isRibbonClosed = true;

    if (this.ribbonElement) {
      this.ribbonElement.style.height = '0px';
      this.isRibbonCollapsed = true;
    }

    if (this.rootElement) {
      this.rootElement.style.marginTop = '';
    }
    setTimeout(() => {
      this.getGlobalHeaderTopDistance();
    }, 300); // waiting for animation
  }

  protected handleRibbonReady() {
    this.updateRibbonSpacing();
  }

  protected handleScroll() {
    setTimeout(() => {
      this.active = true;
      this.updatePreventTransparent();
    });
  }

  protected handleProgressScroll() {
    setTimeout(() => {
      this.updateProgressBar();
    });
  }

  protected handleResize() {
    setTimeout(() => {
      this.updateRibbonSpacing();
      if (this.transparent) {
        this.updatePreventTransparent();
      }
    });
  }

  protected async handlePrimaryNavDocumentClick() {
    const isFocus = await isFocusWithin(this.navListElement);

    if (isFocus) {
      return;
    }

    this.toggleSecNavigation(null, 'collapse');
  }

  protected handlePrimaryNavKeyDown(event: KeyboardEvent) {
    const isSpacebar = /^ $/i.test(event.key);
    const isEnter = /^enter$/i.test(event.key);

    if (!isSpacebar && !isEnter) {
      return;
    }

    const target = (event.currentTarget as HTMLElement)?.closest(
      '.menuItemPrimaryNav',
    ) as HTMLElement;

    this.toggleSecNavigation(target);
  }

  protected handlePrimaryNavMouseDown(event: MouseEvent) {
    const target = (event.currentTarget as HTMLElement)?.closest(
      '.menuItemPrimaryNav',
    ) as HTMLElement;
    const button = (event.target as HTMLElement)?.closest('.actionButton') as HTMLElement;

    if (button) {
      this.skipNextBlur = true;
      event.preventDefault();
      event.stopImmediatePropagation();
      this.toggleSecNavigation(target);
      button.focus();
    }
  }

  protected async handlePrimaryNavFocus(event: FocusEvent) {
    const menuItem = (event.target as HTMLElement)?.closest('.menuItemPrimaryNav') as HTMLElement;
    const isExpanded = !!menuItem?.querySelector('.is-expanded');

    if (isExpanded || this.skipNextBlur) {
      return;
    }

    this.toggleSecNavigation(null, 'collapse');
  }

  protected async handlePrimaryNavBlur() {
    const menuItem = this.navListElement?.querySelector('.is-expanded') as HTMLElement;
    const menuItemMobile = this.navListMobileElement?.querySelector('.is-expanded') as HTMLElement;
    const skipClosing = (await isFocusWithin(menuItem)) || (await isFocusWithin(menuItemMobile));

    if (skipClosing || this.skipNextBlur) {
      this.skipNextBlur = false;
      return;
    }

    this.toggleSecNavigation(null, 'collapse');
  }

  protected onSectionNavigationClick(event: FocusEvent) {
    const el = (event.target as HTMLElement)?.closest('a') as HTMLAnchorElement;
    if (!el?.closest('a')) {
      event.stopPropagation();
    }
  }

  protected onScapeKeyPress(event: KeyboardEvent) {
    const isEscape = /^esc/i.test(event.key);

    if (isEscape) {
      this.toggleSecNavigation(null, 'collapse');
    }
  }

  protected handleSearchContentExpand() {
    this.searchContentIsExpanded = true;
    this.toggleSecNavigation(null, 'collapse');
  }

  protected handleSearchContentCollapse() {
    this.searchContentIsExpanded = false;
  }

  protected getSlottedRibbon() {
    const [slottedElement] = [...(this.slotRibbon?.assignedNodes() || [])] as HTMLElement[];
    return slottedElement;
  }

  protected getSlottedNotifications() {
    const [slottedElement] = [...(this.slotNotifications?.assignedNodes() || [])] as HTMLElement[];
    return slottedElement;
  }

  protected updateRibbonSpacing() {
    const slottedRibbon = this.getSlottedRibbon();

    if (slottedRibbon && !this.isRibbonClosed && !this.isRibbonCollapsed) {
      const { height = 0 } = slottedRibbon.getBoundingClientRect();

      if (this.ribbonElement) {
        this.ribbonElement.style.height = `${height}px`;
      }

      if (this.rootElement) {
        this.rootElement.style.marginTop = /auto/.test(this.headerHeight) ? `${height}px` : '';
      }
    }
  }

  /*
   * Start: mobile templates
   */

  protected getMobileSubNavigationSection() {
    const { navMobileActions } = useStyles.classes;

    return html`
      <div class="${navMobileActions}">
        <div>
          ${when(!this.campaign && this.isMobile, () =>
            this.headerBaseComponentsMixin.getContactUsButtonLink(),
          )}
        </div>
        <div>${this.headerBaseOptionMixin.getLanguageOptionComponent()}</div>
      </div>
    `;
  }

  protected getMobileMenuContainer() {
    const { navMobile, navMobileList, campaignMobile, navMobileForm } = useStyles.classes;

    const primaryNavigationClasses = classMap({
      [navMobile]: true,
      'is-open': this.menuIsOpen,
      'nav-mobile': true,
      'primary-navigation': true,
    });

    return html`
      <nav
        id="mobile-primary-navigation"
        class="${primaryNavigationClasses}"
        aria-label="Primary Navigation"
      >
        ${this.campaign
          ? html`
              <div class="${campaignMobile}">
                <span class="spacer"></span>
                ${this.headerBaseOptionMixin.getLanguageOptionComponent()}
              </div>
              <slot name="campaign-links-mobile"></slot>
              <slot name="campaign-buttons-mobile"></slot>
            `
          : undefined}
        ${when(!this.campaign, () => this.getMobileSubNavigationSection())}

        <div id="nav-list-mobile">
          <div class="${navMobileForm}">
            ${!this.campaign
              ? this.headerBaseOptionMixin.getMobileSearchNavigationOptionComponent()
              : undefined}
          </div>
          ${this.showMobileGenetecNavigation
            ? html`
                <ul
                  class="${navMobileList} nav-list-mobile"
                  role="menubar"
                  aria-label="Primary Navigation"
                >
                  ${repeat(
                    this.menuList || [],
                    (section) => section.name,
                    (section) =>
                      this.getMenuItemMobile(
                        section.name,
                        section.primaryNavigationLink,
                        section.menuGroup,
                      ),
                  )}
                </ul>
              `
            : undefined}
        </div>
      </nav>
    `;
  }

  protected getMenuItemMobile(name: string, linkProps: LinkType, menuGroup: MenuGroup[]) {
    const { navMobileLink } = useStyles.classes;
    const mobileNavLinkClasses = classMap({
      [navMobileLink]: true,
    });
    const hasSecondaryNavigation = menuGroup?.length > 0;

    const onBackButtonClicked = (event: MouseEvent) => {
      const menuItem = (event.target as HTMLElement)?.closest('.menuItemPrimaryNav') as HTMLElement;
      this.toggleSecNavigation(menuItem, 'collapse');
    };

    return html`<li role="menuitem" data-name="${name}" class="menuItemPrimaryNav">
      <slot name="${name}"></slot>
      ${hasSecondaryNavigation
        ? this.getMenuButtonLink({ linkProps, classes: mobileNavLinkClasses })
        : this.headerBaseComponentsMixin.getLink({
            linkProps,
            classes: mobileNavLinkClasses,
          })}
      ${hasSecondaryNavigation
        ? this.secondaryNavigation.renderMobileDropdownContent(menuGroup, onBackButtonClicked)
        : undefined}
    </li>`;
  }

  /*
   * End: desktop templates
   */

  /*
   * Start: mobile templates
   */

  protected getMenuItem(name: string, linkProps: LinkType, menuGroup: MenuGroup[]) {
    const { navLink: navLinkClass } = useStyles.classes;
    const navLinkClasses = classMap({
      [navLinkClass]: true,
    });
    const hasSecondaryNavigation = menuGroup?.length > 0;

    return html`<li role="menuitem" data-name="${name}" class="menuItemPrimaryNav">
      <slot name="${name}"></slot>
      ${hasSecondaryNavigation
        ? this.getMenuButtonLink({ linkProps, classes: navLinkClasses })
        : this.headerBaseComponentsMixin.getLink({
            linkProps,
            classes: navLinkClasses,
          })}
      ${hasSecondaryNavigation
        ? this.secondaryNavigation.renderDesktopDropdownContent(menuGroup)
        : undefined}
    </li>`;
  }

  protected getMenu() {
    const { menu_arial_label: menuArialLabel } = this.labels || {};
    const { nav: navClass, navList: navListClass } = useStyles.classes;
    const navClasses = classMap({
      nav: true,
      [navClass]: true,
    });

    const navListClasses = classMap({
      'nav-list': true,
      [navListClass]: true,
    });

    return html`
      <nav class="${navClasses}" aria-label="${menuArialLabel}">
        <slot name="menuList"></slot>
        <ul class="${navListClasses}" role="menubar" aria-label="${menuArialLabel}">
          <slot name="campaign-links"></slot>
          ${repeat(
            this.menuList || [],
            (section) => section.name,
            (section) =>
              this.getMenuItem(section.name, section.primaryNavigationLink, section.menuGroup),
          )}
        </ul>
      </nav>
    `;
  }

  protected getMenuButtonLink({
    linkProps,
    classes,
  }: {
    linkProps: LinkType;
    classes: ReturnType<typeof classMap>;
  }) {
    const { displayText } = linkProps;

    return html`
      <button
        class="${classes} actionButton"
        aria-haspopup="true"
        aria-expanded="false"
        tabindex="0"
      >
        <span class="text">${displayText}</span>
      </button>
    `;
  }

  protected hideSecNavigation() {
    const menuItem = this.rootElement
      ?.querySelector('.menuItemPrimaryNav .secNavMain.is-expanded')
      ?.closest('.menuItemPrimaryNav') as HTMLElement;
    if (menuItem) {
      this.toggleSecNavigation(menuItem, 'collapse');
    }
  }

  protected toggleMobileNavigationVisibility(force?: boolean) {
    this.menuIsOpen = force !== undefined ? force : !this.menuIsOpen;
    emitGlobalHeaderNavigationToggle(this.menuIsOpen);
    this.hideSecNavigation();
    toggleBackgroundOverlay(this.menuIsOpen);

    if (this.mobilePrimaryNavigationRef) {
      animate(this.mobilePrimaryNavigationRef, this.menuIsOpen);
    }

    // reset primary navigation visibility
    this.handleLanguageOptionToggle({ detail: { isExpanded: false } } as any);
  }

  protected closeMobileNavigation() {
    this.menuIsOpen = false;
  }

  protected getGlobalHeaderNavigationContainer() {
    const { genetec_logo: genetecLogoUrl, account_icon: accountIconUrl } = this.config || {};
    const { genetec_logo: genetecLogoLabel } = this.labels || {};
    const {
      headerContainer: headerContainerClass,
      spacer: spacerClass,
      altNav: altNavClass,
      campaignButtons: campaignButtonsClass,
    } = useStyles.classes;

    const isLoginMobile = true;

    const { isUserLogged, userApproved } = this.sessionConfig || {};
    const { notificationsEnabled } = this.notificationConfig || {};
    const displayAppsOption = isUserLogged && userApproved;
    const displayNotificationOption = isUserLogged && notificationsEnabled;

    return html`
      <div class="${headerContainerClass}">
        ${when(!this.hideMobileNavigationButton, () =>
          this.headerBaseComponentsMixin.getHamburgerMenuButton({
            onClick: () => this.toggleMobileNavigationVisibility(),
          }),
        )}
        ${when(genetecLogoUrl, () =>
          this.headerBaseComponentsMixin.getLogo(this.getUrl(genetecLogoUrl), genetecLogoLabel),
        )}
        ${when(
          this.isMobile,
          () => html` <slot name="campaign-logos-mobile"></slot> `,
          () => html`<slot name="campaign-logos"></slot>`,
        )}

        <span class="${spacerClass}"></span>
        ${when(!this.isMobile && !this.hideNavigation, () => this.getMenu())}
        ${when(!this.campaign && !this.isMobile && !this.hideNavigation, () =>
          this.headerBaseComponentsMixin.getContactUsButtonLink(),
        )}
        ${when(
          !this.isMobile,
          () =>
            html`<div class="${altNavClass}">
              ${!this.campaign
                ? this.headerBaseOptionMixin.getSearchNavigationOptionComponent()
                : undefined}
              ${when(displayAppsOption, () => this.headerBaseOptionMixin.getAppsOptionComponent())}
              ${when(displayNotificationOption, () =>
                this.headerBaseOptionMixin.getNotificationsOptionComponent(),
              )}
              ${this.headerBaseOptionMixin.getLoginOptionComponent()}
              ${this.headerBaseOptionMixin.getLanguageOptionComponent()}
            </div>`,
        )}
        ${when(this.isMobile && displayAppsOption, () =>
          this.headerBaseOptionMixin.getAppsOptionComponent(),
        )}
        ${when(this.isMobile && displayNotificationOption, () =>
          this.headerBaseOptionMixin.getNotificationsOptionComponent(),
        )}
        ${when(!this.isExternal && accountIconUrl && this.isMobile, () =>
          this.headerBaseOptionMixin.getLoginOptionComponent(isLoginMobile),
        )}
        <div class="${campaignButtonsClass}">
          <slot name="campaign-buttons"></slot>
        </div>
      </div>
    `;
  }

  protected getProgressBar() {
    const { headerProgress: headerProgressClass } = useStyles.classes;

    const progressClasses = classMap({
      'header-progress': true,
      [headerProgressClass]: true,
      'is-visible': this.hasProgressBar || this.loading,
    });

    const progressBackgroundClasses = classMap({
      'header-progress-background': true,
      'is-transparent': this.transparent && !this.preventTransparent,
    });

    const progressBarClasses = classMap({
      'header-progress-bar': true,
      'is-loading': this.loading,
    });

    return html`<div class="${progressClasses}">
      <div class="${progressBackgroundClasses}"></div>
      <div class="${progressBarClasses}"></div>
    </div>`;
  }

  protected getGlobalHeader() {
    const {
      root: rootClass,
      header: headerClass,
      headerContent: headerContentClass,
      ribbon: ribbonClass,
    } = useStyles.classes;

    const rootClasses = classMap({
      'header-wrapper': true,
      'is-active': this.active,
      'is-height-auto': /auto/.test(this.headerHeight),
      'is-transparent': this.transparent,
      'has-progress': this.hasProgressBar,
      [rootClass]: true,
    });
    const ribbonClasses = classMap({
      ribbon: true,
      [ribbonClass]: true,
    });
    const headerContentClasses = classMap({
      [headerContentClass]: true,
      'is-sticky': this.sticky,
    });

    const navigationContentIsExpanded = this.secNavExpanded || this.searchContentIsExpanded;

    const headerClasses = classMap({
      header: true,
      [headerClass]: true,
      'is-active': this.active,
      'is-transparent': this.transparent && !this.preventTransparent,
      'is-expanded': navigationContentIsExpanded,
      // 'is-open': this.menuIsOpen,
      'has-progress': this.hasProgressBar,
    });

    return html`
      <div class="${rootClasses}">
        <div class="${headerContentClasses}">
          <div class="${ribbonClasses}">
            <slot name="news-ribbon"></slot>
          </div>
          <div id="global-header" class="${headerClasses}">
            ${this.getGlobalHeaderNavigationContainer()} ${this.getProgressBar()}
          </div>
          ${this.isMobile ? this.getMobileMenuContainer() : ''}
        </div>
      </div>
    `;
  }

  protected render() {
    return html`${this.getGlobalHeader()}`;
  }

  /*
   * End: mobile templates
   */

  protected shouldUpdate(_changedProperties: PropertyValues) {
    if (_changedProperties.has('menuList')) {
      this.menuList = safeJSONParse(this.menuList);
    }

    if (_changedProperties.has('languageList')) {
      this.languageList = safeJSONParse(this.languageList);
    }

    if (_changedProperties.has('config')) {
      this.config = safeJSONParse(this.config);
    }

    if (_changedProperties.has('labels')) {
      this.labels = safeJSONParse(this.labels);
    }

    if (_changedProperties.has('loginSection')) {
      this.loginSection = safeJSONParse(this.loginSection);
    }

    if (_changedProperties.has('sessionConfig')) {
      this.sessionConfig = safeJSONParse(this.sessionConfig);
    }

    if (_changedProperties.has('languageConfig')) {
      this.languageConfig = safeJSONParse(this.languageConfig);
    }

    if (_changedProperties.has('notificationConfig')) {
      this.notificationConfig = safeJSONParse(this.notificationConfig);
    }

    if (_changedProperties.has('hideMobileNavigationButton')) {
      this.hideMobileNavigationButton = safeJSONParse(this.hideMobileNavigationButton);
    }

    if (_changedProperties.has('hideNavigation')) {
      this.hideNavigation = safeJSONParse(this.hideNavigation);
      console.log('hideNavigation', this.hideNavigation);
    }

    return super.shouldUpdate(_changedProperties);
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);

    this.shadowRoot?.addEventListener('click', (e: any) => this.handleShadowRootClick(e));
    this.slotRibbon?.addEventListener('slotchange', this.onSlotRibbonChange);

    // start: Search content events
    this.searchNavigationElementRef?.addEventListener('expand', (event) => {
      this.handleSearchContentExpand();
      this.showMobileGenetecNavigation =
        (event as CustomEvent).detail?.searchStatus === 'NO RESULTS';
    });
    this.searchNavigationElementRef?.addEventListener('collapse', () => {
      this.handleSearchContentCollapse();
    });
    // end: Search content events

    window.addEventListener('resize', this.onDocumentResize);
    window.addEventListener('orientationchange', this.onOrientationChange);

    setTimeout(() => {
      this.updatePreventTransparent();
    });

    onCloseHeaderMenuEvent(() => {
      if (this.isMobile) {
        this.closeMobileNavigation();
      }
    });

    // listening to other menus toggle activity
    onOtherHeaderOptionToggled(({ menuToggled, toggleValue }) => {
      const excludedMenus = [HeaderMenus.LanguageSection, HeaderMenus.SearchNavigation];
      if (!this.isMobile) {
        if (toggleValue) {
          this.hideSecNavigation();
        }
        return; // only for mobile
      }
      if (!excludedMenus.includes(menuToggled)) {
        toggleBackgroundOverlay(toggleValue);
        if (toggleValue) this.closeMobileNavigation();
      }
    });
  }

  protected updated(_changedProperties: PropertyValues) {
    super.update(_changedProperties);

    if (_changedProperties.has('headerHeight')) {
      this.updateRibbonSpacing();
    }

    if (_changedProperties.has('locked')) {
      this.updatePreventTransparent();
    }

    if (_changedProperties.has('menuList')) {
      if (!this.menuList) return;

      this.addPrimaryNavListeners();
    }

    if (_changedProperties.has('hasProgressBar')) {
      if (this.hasProgressBar) {
        document.addEventListener('scroll', this.globalHandlers.onProgressScroll);
      } else {
        document.removeEventListener('scroll', this.globalHandlers.onProgressScroll);
      }
    }

    if (_changedProperties.has('sticky') || _changedProperties.has('transparent')) {
      if (this.sticky || this.transparent) {
        document.addEventListener('scroll', this.globalHandlers.onScroll);
        window.addEventListener('resize', this.globalHandlers.onResize);
      } else {
        document.removeEventListener('scroll', this.globalHandlers.onScroll);
        window.removeEventListener('resize', this.globalHandlers.onResize);
      }
    }
  }

  protected updateProgressBar() {
    const { documentElement } = document;
    const footer = document.querySelector('gntc-global-footer');
    const footerHeight = footer?.clientHeight || 0;
    const documentHeight = documentElement.scrollHeight;
    const scrollHeight = documentHeight - footerHeight - window.innerHeight;
    const scrollTop = documentElement.scrollTop;
    const scroll = scrollHeight > 0 ? (scrollTop / scrollHeight) * 100 : 100;

    if (this.headerProgressBarElement) {
      this.headerProgressBarElement.style.width = `${scroll}%`;
    }
  }

  protected updatePreventTransparent() {
    const { top } = this.rootElement?.getBoundingClientRect() || {};

    if (top !== undefined) {
      this.preventTransparent = !this.locked && top < 0;
    }
  }

  protected getUrl(path: string): string {
    return getHref({ url: path, spaBaseUrl: this.spaBaseUrl });
  }

  protected addPrimaryNavListeners() {
    const primaryMenuItems =
      Array.from(this.rootElement?.querySelectorAll('.menuItemPrimaryNav') as any) || [];

    if (primaryMenuItems.length == 0) return;

    primaryMenuItems.map((menuItem: any) => {
      this.removePrimaryMenuItemListeners(menuItem);
      this.addPrimaryMenuItemListeners(menuItem);
    });
  }

  static getSecondNavigation(menuItem: HTMLElement) {
    return menuItem.querySelector('.secNavMain') as HTMLElement;
  }

  protected removePrimaryMenuItemListeners(menuItem: HTMLElement) {
    const button = menuItem.querySelector('button');
    const secondNavigation = GlobalHeaderBase.getSecondNavigation(menuItem);

    document.removeEventListener('click', this.primaryNavHandlers.onDocumentClick);
    button?.removeEventListener('blur', this.primaryNavHandlers.onBlur);
    button?.removeEventListener('focus', this.primaryNavHandlers.onFocus);
    button?.removeEventListener('mousedown', this.primaryNavHandlers.onMouseDown);
    button?.removeEventListener('keydown', this.primaryNavHandlers.onKeyDown);

    secondNavigation?.removeEventListener('mousedown', (event: FocusEvent) =>
      this.onSectionNavigationClick(event),
    );
    secondNavigation?.removeEventListener('click', (event: FocusEvent) =>
      this.onSectionNavigationClick(event),
    );

    this.navListElement?.removeEventListener('keyup', (event: KeyboardEvent) =>
      this.onScapeKeyPress(event),
    );
  }

  protected addPrimaryMenuItemListeners(menuItem: HTMLElement) {
    const button = menuItem.querySelector('button');
    const secondNavigation = GlobalHeaderBase.getSecondNavigation(menuItem);

    document.addEventListener('click', this.primaryNavHandlers.onDocumentClick);
    button?.addEventListener('blur', this.primaryNavHandlers.onBlur);
    button?.addEventListener('focus', this.primaryNavHandlers.onFocus);
    button?.addEventListener('mousedown', this.primaryNavHandlers.onMouseDown);
    button?.addEventListener('keydown', this.primaryNavHandlers.onKeyDown);

    secondNavigation?.addEventListener('mousedown', (event: FocusEvent) =>
      this.onSectionNavigationClick(event),
    );
    secondNavigation?.addEventListener('click', (event: FocusEvent) =>
      this.onSectionNavigationClick(event),
    );

    this.navListElement?.addEventListener('keyup', (event: KeyboardEvent) =>
      this.onScapeKeyPress(event),
    );
  }

  protected toggleSecNavigation(menuItem: HTMLElement | null, force?: 'expand' | 'collapse') {
    const menuItems = (this.navListElement?.querySelectorAll('.menuItemPrimaryNav') ||
      []) as HTMLElement[];
    const button = menuItem?.querySelector('.actionButton') as HTMLElement;
    const secNav = menuItem?.querySelector('.secNavMain') as HTMLElement;
    const name = menuItem?.getAttribute('data-name') || '';
    const ariaExpanded = /^true/i.test(button?.getAttribute('aria-expanded') || '');
    const action = force || (ariaExpanded ? 'collapse' : 'expand');
    const isExpanded = /expand/.test(action);

    menuItems.forEach((item) => {
      if (!menuItem || menuItem !== item) {
        const _button = item?.querySelector('.actionButton') as HTMLElement;
        const _secNav = item?.querySelector('.secNavMain') as HTMLElement;

        _secNav?.classList.toggle('is-expanded', false);
        this.setButtonAriaAttributes(_button, false);
      }
    });

    if (!menuItem) {
      this.secNavExpanded = false;
      return;
    }

    this.rootElement?.classList.toggle('is-expanded', isExpanded);
    secNav?.classList.toggle('is-expanded', isExpanded);
    this.secondaryNavigation.toggleExpanded(secNav);
    this.setButtonAriaAttributes(button, isExpanded);

    if (secNav) {
      if (this.isMobile) {
        const { top = 0 } = this.navMobileElement?.getBoundingClientRect() || {};
        secNav.style.top = `${top}px`;
        secNav.style.height = `calc(100% - ${top}px)`;
      } else {
        secNav.style.top = secNav.style.height = '';
      }
    }

    this.secNavExpanded = isExpanded;

    if (isExpanded) {
      this.menuItemExpandedNames[name] = true;
    }
  }

  protected setButtonAriaAttributes(button: any, expanded: boolean) {
    button.setAttribute('aria-expanded', expanded);
  }

  protected showSecNavigationText(menuSecNav: any) {
    const menuSecNavContainer = menuSecNav.querySelector('.secNavContainer');
    setTimeout(() => {
      menuSecNavContainer.classList.add('show-text');
    }, 100);
  }

  protected hideSecNavigationText(menuSecNav: any) {
    const menuSecNavContainer = menuSecNav.querySelector('.secNavContainer');
    menuSecNavContainer.classList.remove('show-text');
  }
}
